import { createGlobalStyle, css } from 'styled-components';
import { fluidRange, rem } from 'polished';
import * as v from './variables';

const fonts = {
  sans: css`
    font-family: 'Montserrat', Helvetica Neue, sans-serif;
  `,
  serif: css`
    font-family: 'Palatino Linotype', Palatino, 'Book Antiqua', Garamond,
      'Apple Garamond', Georgia, serif;
  `,
  mono: css`
    font-family: Consolas, 'DejaVu Sans Mono', 'Liberation Mono',
      'Nimbus Mono L', Monaco, 'Courier New', Courier, monospace;
  `,
  cursive: css`
    font-family: 'Yellowtail', serif;
  `,
};

const Typography = createGlobalStyle`
  html {
    font-family: 'Montserrat', 'Helvetica Neue', sans-serif;
    color: ${({ theme }) => {
      return theme.colors.dark;
    }};
  }

  body {
    ${fluidRange(
      {
        prop: 'font-size',
        fromSize: rem(13),
        toSize: rem(22),
      },
      rem(v.FLUID_MIN_WIDTH),
      rem(v.FLUID_MAX_WIDTH)
    )}
    color: ${({ theme }) => {
      return theme.colors.dark;
    }};
    letter-spacing: 0.02em;
    line-height: 1.6;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
  }

  a {
    color: ${({ theme }) => {
      return theme.colors.dark;
    }};
  }

  i {
    font-style: normal;
  }

  em, .emphasize {
    ${({ theme }) => {
      return theme.mixins.emphasize;
    }}
  }

  p.text-content {
    ${fonts.serif};

    &.emphasize {
      ${fonts.sans};
    }
  }

  .text-content {
    &.font-sans {
      ${fonts.sans};
    }
    em, .emphasize {
      ${fonts.sans};
    }
  }

  .font-center {
    text-align: center;
  }

  .font-sans {
    ${fonts.sans};
  }

  .font-serif {
    ${fonts.serif};
  }

  .font-mono {
    ${fonts.mono};
  }

  .font-italic {
    font-style: italic;
  }

  .font-cursive {
    ${fonts.cursive};
  }

  .font-spacing-0 {
    letter-spacing: 0.02em;
  }

  .font-spacing-1 {
    letter-spacing: 0.04em;
  }

  .font-spacing-2 {
    letter-spacing: 0.06em;
  }

  .font-spacing-3 {
    letter-spacing: 0.08em;
  }

  .font-spacing-4 {
    letter-spacing: 0.1em;
  }

  .font-spacing-5 {
    letter-spacing: 0.12em;
  }
`;

export default Typography;
