import React, { useEffect, useRef, useContext } from 'react';
import styled from 'styled-components';
import SvgMorph from './SvgMorph';
import { useMobileMenu } from '../context/mobileMenu';
import { bp } from '../styles/mediaBreakpoints';
import { LazyLoadContext } from '../context/lazyLoadedJs';

const pathVariants = {
  active: [
    'M 215.83457,180.48166 65.92808,30.575171 C 65.92808,30.575171 44.71488,9.3619687 27.037204,27.039648 9.3595357,44.717317 30.572743,65.930512 30.572743,65.930512 L 180.47923,215.837 Z',
    'M 446,30.5 30.5,446 C 30.5,446 9.3223299,467.32233 27,485 44.67767,502.67767 66,481.5 66,481.5 L 481.5,66 C 481.5,66 502.67767,44.67767 485,27 467.32233,9.32233 446,30.5 446,30.5 Z',
    'M 481.43781,446.07554 331.53152,296.16924 296.17618,331.52458 446.08247,481.43088 C 446.08247,481.43088 467.29567,502.64408 484.97334,484.96641 502.65101,467.28874 481.43781,446.07554 481.43781,446.07554 Z',
  ],
  inactive: [
    'M 462,0 H 50 C 50,0 20,0 20,25 20,50 50,50 50,50 H 462 C 462,50 492,50 492,25 492,0 462,0 462,0 Z',
    'M 50,231.577 C 50,231.577 20,231.57675 20,256.57675 20,281.57675 50,281.57675 50,281.57675 H 462 C 462,281.57675 492,281.57675 492,256.57675 492,231 462,231.577 462,231.577 462,231.577 111.14479,231.577 50,231.577 Z',
    'M 462,462.00049 H 50 C 50,462.00049 20,462.00049 20,487.00049 20,512.00049 50,512.00049 50,512.00049 H 462 C 462,512.00049 492,512.00049 492,487.00049 492,462.00049 462,462.00049 462,462.00049 Z',
  ],
  initial: ['M 256,256 Z', 'M 256,256 Z', 'M 256,256 Z'],
};

const size = 2.6;
const padding = 0.5;
const margin = 0.5;
const right = 0.5;
export const toggleWidth = size + margin + 2 * padding + right;
const Wrapper = styled.button`
  color: #fff;
  position: absolute;
  right: ${right}em;
  top: 50%;
  transform: translateY(-50%);
  margin-left: ${margin}em;
  padding: ${padding}em;
  height: ${size}em;
  width: ${size}em;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${bp.md}px) {
    display: none;
  }
`;
const TopMenuToggle = () => {
  const [modules, moduleActions] = useContext(LazyLoadContext);
  const [menuState, menuDispatch] = useMobileMenu();
  const wrapperEl = useRef();
  let changeIconVariant = () => {};
  useEffect(() => {
    if (menuState.isActive) {
      wrapperEl.current.classList.add('active');
    } else {
      wrapperEl.current.classList.remove('active');
    }
    if (menuState.isActive) {
      changeIconVariant('active');
    } else {
      changeIconVariant('inactive');
    }
  }, [menuState.isActive]);
  return (
    <Wrapper
      ref={wrapperEl}
      aria-label="Toggle mobile menu's visibility"
      onClick={() => {
        menuDispatch({ type: 'toggle' });
      }}
    >
      <SvgMorph
        kutejs={modules.kutejs}
        pathVariants={pathVariants}
        changeVariant={(handler) => {
          return (changeIconVariant = handler);
        }}
        initialVariant="inactive"
        viewBox="0 0 512 512"
        fill="white"
      />
    </Wrapper>
  );
};

export default TopMenuToggle;
