import { css } from 'styled-components';
import { darken, lighten } from 'polished';

export const TOP_BAR_BOTTOM_BORDER = 8;
export const TOP_BAR_CONTENT_HEIGHT = 60;
export const WRAPPER_MAX_WIDTH = 1000;
export const WRAPPER_SLIM_MAX_WIDTH = 800;
export const FLUID_MAX_WIDTH = WRAPPER_MAX_WIDTH;
export const FLUID_MIN_WIDTH = 320;
export const UNDERLINE_LINE_HEIGHT = 1.4;
export const UNDERLINE_DEGREE = -0.7;
export const BUTTON_BORDER_WIDTH = 2;
export const BUTTON_DEGREE = -10;
export const SKEW_CLIP_PATH_SIZE_EM = 1;
export const SKEW_CLIP_PATH_SIZE_PX = 20;
export const CODE_BLOCK_FONT_SIZE_EM = 0.8;

// export const colors = {
//   dark: '#141414',
//   blueA: '#073A73',
//   blueB: '#2c88f2', // additional
//   blueC: '#A0C9F8',
//   blueD: '#D9E9FD',
//   brownA: '#7A3900',
//   brownB: '#FF871F', // additional
//   brownC: '#FFC999',
//   brownD: '#FFE9D6',
//   greenA: '#505C0F',
//   greenB: '#BAD123',
//   greenC: '#F5F9DC',
//   light: '#F5F5F5',
//   // dark red: AB0000
//   // medium red:
//   // light red: FFB8B8
// };

const generateColorVariants = (color, prefix, interval) => {
  const colorVariants = {};
  for (
    let lightenPercentage = 5;
    lightenPercentage < 100;
    lightenPercentage += interval
  ) {
    colorVariants[
      `${prefix}${
        lightenPercentage < 10 ? `0${lightenPercentage}` : lightenPercentage
      }`
    ] = lighten(lightenPercentage / 100, color);
  }
  return colorVariants;
};

export const colors = {
  black: '#000000',
  dark: '#141414',
  blue: '#073A73',
  darkBlue: darken(0.08, '#073A73'),
  brown: '#7A3900',
  light: '#F5F5F5',
  white: '#FFFFFF',
  ...generateColorVariants('#141414', 'dark', 15),
  ...generateColorVariants('#073A73', 'blue', 5),
  ...generateColorVariants('#7A3900', 'brown', 5),
};
// brown35: #ff8f2e
// console.log(`%c colors %O`, 'font-size: large;', colors);

export const scrollbar = {
  backgroundColor: colors.blue55,
  thumbColor: colors.brown25,
  webkit: {
    size: '0.6rem',
    backgroundColor: colors.blue55,
    thumbColor: colors.brown25,
    thumbHoverColor: colors.brown15,
    thumbActiveColor: colors.blue,
  },
};

export const mixins = {
  patterns: {
    // https://www.heropatterns.com/
    rain: (theme, color) => {
      return css`
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='16' viewBox='0 0 12 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4 .99C4 .445 4.444 0 5 0c.552 0 1 .45 1 .99v4.02C6 5.555 5.556 6 5 6c-.552 0-1-.45-1-.99V.99zm6 8c0-.546.444-.99 1-.99.552 0 1 .45 1 .99v4.02c0 .546-.444.99-1 .99-.552 0-1-.45-1-.99V8.99z' fill='%23${color.slice(
          1
        )}' fill-opacity='0.4' fill-rule='evenodd'/%3E%3C/svg%3E");
      `;
    },
    texture: (theme, color) => {
      return css`
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23${color.slice(
          1
        )}' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
      `;
    },
  },
  useSkewed: (fragment) => {
    return css`
      @supports (
        (-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)) or
          (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%))
      ) {
        ${fragment}
      }
    `;
  },
  useSkewedFallback: (fragment) => {
    return css`
      @supports not (
        (-webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%)) or
          (clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%))
      ) {
        ${fragment}
      }
    `;
  },
  skew: (deg = BUTTON_DEGREE) => {
    return `skewX(${deg}deg)`;
  },
  unskew: (deg = BUTTON_DEGREE) => {
    return `skewX(${-1 * deg}deg)`;
  },
  emphasize: () => {
    return css`
      font-size: 1.25em;
      font-weight: 500;
      font-style: normal;
    `;
  },
};
