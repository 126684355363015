import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            defaultDescription: description
            baseUrl
            siteUrl
            fullUrl
            cvUrl
            siteLanguage
            siteLocale
            logo
            defaultBanner: banner
            favicon
            shortName
            author
            themeColor
            backgroundColor
            twitter
            twitterUrl
            linkedInUrl
            githubUrl
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
