import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

const buttonStyles = css`
  ${({ theme, ...props }) => {
    return css`
      color: ${theme.colors[props.$color]};
      background-color: ${theme.colors[props.$background]};
      transition: color 0.2s, background-color 0.2s;
      border: 2px solid ${theme.colors.dark};
      display: inline-block;
      box-shadow: 2px 3px 4px 0px #000000;
      box-sizing: border-box;
      padding: 0.1em 0.6em;

      .button__icon {
        color: ${theme.colors[props.$iconColor]};
        margin-right: ${0.5 / 0.8}em;
        font-size: 0.8em;
      }

      .button__content {
        display: inline-block;
      }

      &:active {
        position: relative;
        top: 1px;
        box-shadow: 2px 2px 3px 0px #000000;
      }

      &[disabled],
      &:disabled {
        color: ${theme.colors[props.$colorDisabled]};
        background-color: ${theme.colors[props.$backgroundDisabled]};
        pointer-events: none;

        .button__icon {
          color: ${theme.colors[props.$iconColorDisabled]};
        }

        &:hover {
          color: ${theme.colors[props.$colorDisabled]};
          background-color: ${theme.colors[props.$backgroundDisabled]};

          .button__icon {
            color: ${theme.colors[props.$iconColorDisabled]};
          }
        }
      }

      &:hover {
        color: ${theme.colors[props.$colorHover]};
        background-color: ${theme.colors[props.$backgroundHover]};

        .button__icon {
          color: ${theme.colors[props.$iconColorHover]};
        }
      }
    `;
  }}

  ${({ theme }) => {
    return theme.mixins.useSkewed(
      css`
        transform: ${theme.mixins.skew()};
        .button__content {
          transform: ${theme.mixins.unskew()};
        }
      `
    );
  }}
`;

const WrapperButton = styled.button`
  ${buttonStyles}
  cursor: pointer;
`;

const WrapperLink = styled(Link)`
  ${buttonStyles}
`;

const WrapperExternalLink = styled.a`
  ${buttonStyles}
`;

const Button = React.forwardRef(
  (
    {
      $type = 'button',
      children,
      icon,
      disabled,
      $iconAlt,
      $color = 'white',
      $colorHover = 'white',
      $colorDisabled = 'dark35',
      $iconColor = 'white',
      $iconColorHover = 'white',
      $iconColorDisabled = 'dark35',
      $background = 'blue',
      $backgroundHover = 'brown35',
      $backgroundDisabled = 'light',
      to,
      external = false,
      handleClick,
      ...rest
    },
    ref
  ) => {
    return (
      (to && !external && (
        <WrapperLink
          $type={$type}
          disabled={disabled}
          $iconAlt
          $color={$color}
          $colorHover={$colorHover}
          $colorDisabled={$colorDisabled}
          $iconColor={$iconColor}
          $iconColorHover={$iconColorHover}
          $iconColorDisabled={$iconColorDisabled}
          $background={$background}
          $backgroundHover={$backgroundHover}
          $backgroundDisabled={$backgroundDisabled}
          to={to}
          ref={ref}
          {...rest}
        >
          <span className="button__content">
            {icon && $iconAlt && <span className="sr-only">{$iconAlt}</span>}
            {icon && <FA className="button__icon" icon={icon} />}
            {children}
          </span>
        </WrapperLink>
      )) ||
      (to && external && (
        <WrapperExternalLink
          $type={$type}
          disabled={disabled}
          $iconAlt
          $color={$color}
          $colorHover={$colorHover}
          $colorDisabled={$colorDisabled}
          $iconColor={$iconColor}
          $iconColorHover={$iconColorHover}
          $iconColorDisabled={$iconColorDisabled}
          $background={$background}
          $backgroundHover={$backgroundHover}
          $backgroundDisabled={$backgroundDisabled}
          href={to}
          ref={ref}
          {...rest}
        >
          <span className="button__content">
            {icon && $iconAlt && <span className="sr-only">{$iconAlt}</span>}
            {icon && <FA className="button__icon" icon={icon} />}
            {children}
          </span>
        </WrapperExternalLink>
      )) ||
      (!to && (
        <WrapperButton
          type={$type}
          disabled={disabled}
          $iconAlt
          $color={$color}
          $colorHover={$colorHover}
          $colorDisabled={$colorDisabled}
          $iconColor={$iconColor}
          $iconColorHover={$iconColorHover}
          $iconColorDisabled={$iconColorDisabled}
          $background={$background}
          $backgroundHover={$backgroundHover}
          $backgroundDisabled={$backgroundDisabled}
          onClick={handleClick}
          ref={ref}
          {...rest}
        >
          <span className="button__content">
            {icon && $iconAlt && <span className="sr-only">{$iconAlt}</span>}
            {icon && <FA className="button__icon" icon={icon} />}
            {children}
          </span>
        </WrapperButton>
      ))
    );
  }
);

export default Button;
