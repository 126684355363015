import { createGlobalStyle } from 'styled-components';
import * as v from './variables';
import { bp } from './mediaBreakpoints';

export const mainTheme = {
  colors: v.colors,
  mixins: v.mixins,
};

const GlobalStyles = createGlobalStyle`
  :root {
  }

  html {
    scrollbar-color: ${v.scrollbar.thumbColor} ${v.scrollbar.backgroundColor};

    &::-webkit-scrollbar {
      width: ${v.scrollbar.webkit.size};
      height: ${v.scrollbar.webkit.size};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${v.scrollbar.webkit.thumbColor};
      &:hover {
        background-color: ${v.scrollbar.webkit.thumbHoverColor};
      }
      &:active {
        background-color: ${v.scrollbar.webkit.thumbActiveColor};
      }
    }
    &::-webkit-scrollbar-track {
      background-color: ${v.scrollbar.webkit.backgroundColor};
    }
  }

  body {
    background-color: white;
    ${({ theme }) => {
      return theme.mixins.patterns.texture(theme, theme.colors.blue60);
    }}

    scrollbar-color: ${v.scrollbar.thumbColor} ${v.scrollbar.backgroundColor};

    &::-webkit-scrollbar {
      width: ${v.scrollbar.webkit.size};
      height: ${v.scrollbar.webkit.size};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${v.scrollbar.webkit.thumbColor};
      &:hover {
        background-color: ${v.scrollbar.webkit.thumbHoverColor};
      }
      &:active {
        background-color: ${v.scrollbar.webkit.thumbActiveColor};
      }
    }
    &::-webkit-scrollbar-track {
      background-color: ${v.scrollbar.webkit.backgroundColor};
    }
  }

  * {
    /* border: 1px solid black; */
  }

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    outline: inherit;
    pointer-events: auto;
    cursor: default;
    -webkit-appearance: none;
    -moz-appearance:    none;
    appearance:         none;

    * {
      cursor: default;
    }
  }

  img {
    max-width: 100%;
  }

  main {
    /* overflow-x: hidden; */
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
  }

  p {
    max-width: 100%;
    box-sizing: border-box;
  }

  .horizontal-pad {
    margin: 2em auto;
    padding: 0 2em;
  }

  .no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    &:focus {
      outline: none !important;
    }
  }

  .sr-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .container {
    max-width: ${v.WRAPPER_MAX_WIDTH}px;
  }

  .container-slim {
    max-width: ${v.WRAPPER_SLIM_MAX_WIDTH}px;
  }

  .underline {
    position: relative;
    display: inline-block;
    line-height: ${v.UNDERLINE_LINE_HEIGHT}em;

    &:before {
      content: '';
      position: absolute;
      bottom: 0.05em;
      left: 0;
      width: 100%;
      height: 0.2em;
      background: ${({ theme }) => {
        return theme.colors.brown35;
      }};
      transition: transform 0.5s;
      transform-origin: left bottom;
      transform: rotate(${v.UNDERLINE_DEGREE}deg);
      z-index: -1;
      @media (prefers-reduced-motion: reduce) {
        transition: opacity 0.3s;
      }
    }

    &.underline--hidden {
      overflow: hidden;
      &:before {
        transform: rotate(${v.UNDERLINE_DEGREE}deg) translateX(-101%);
        @media (prefers-reduced-motion: reduce) {
          transform: rotate(${v.UNDERLINE_DEGREE}deg);
          opacity: 0;
        }
      }
      &.underline--active {
        &:before {
          transform: rotate(${v.UNDERLINE_DEGREE}deg) translateX(0%);
          @media (prefers-reduced-motion: reduce) {
            opacity: 1;
          }
        }
      }
    }
  }

  p.text-content {
    margin-bottom: 0.5em;
  }

  p.section-description {
    margin-bottom: 1.5em;
  }

  .text-content {
    overflow-wrap: break-word;

    p {
      margin-bottom: 0.5em;
    }

    a {
      text-decoration: underline;
      text-underline-position: under;
      text-decoration-thickness: 0.2em;
      color: ${({ theme }) => {
        return theme.colors.blue40;
      }};
      text-decoration-color: ${({ theme }) => {
        return theme.colors.blue55;
      }};
      transition: color 0.2s, text-decoration-color 0.2s;

      &:hover {
        color: ${({ theme }) => {
          return theme.colors.blue30;
        }};
        text-decoration-color: ${({ theme }) => {
          return theme.colors.blue45;
        }};
      }
      &:visited {
        color: ${({ theme }) => {
          return theme.colors.brown40;
        }};
        text-decoration-color: ${({ theme }) => {
          return theme.colors.brown55;
        }};
      &:hover {
        color: ${({ theme }) => {
          return theme.colors.brown30;
        }};
        text-decoration-color: ${({ theme }) => {
          return theme.colors.brown45;
        }};
      }
      }
    }
  }

  .react-icons-icon {
    vertical-align: -0.125em;
  }

  .inline-icon {
    /* display: inline-block; */
    height: 1em;
    width: auto;
  }
`;

export default GlobalStyles;
