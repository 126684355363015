/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import useSiteMetadata from '../hooks/useSiteMetadata';

const SEO = ({
  children,
  location,
  image,
  titlePrefix,
  description,
  article,
}) => {
  const siteMetadata = useSiteMetadata();

  const seoData = {
    title:
      titlePrefix && titlePrefix.length > 0
        ? `${titlePrefix} | ${siteMetadata.defaultTitle}`
        : siteMetadata.defaultTitle,
    description: description || siteMetadata.defaultDescription,
    image:
      image && image.length > 0
        ? `${siteMetadata.siteUrl}/headers/${
            image || siteMetadata.defaultBanner
          }`
        : ``,
    url: `${siteMetadata.siteUrl}${location.pathname}`,
  };

  // default
  let schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    '@id': seoData.url,
    url: seoData.url,
    name: seoData.title,
    headline: seoData.title,
    isPartOf: siteMetadata.siteUrl,
  };

  if (seoData.url === siteMetadata.fullUrl) {
    // index page
    schemaOrgJSONLD = {
			'@context': 'http://schema.org',
			'@type': 'WebSite',
			'@id': siteMetadata.fullUrl,
			url: siteMetadata.fullUrl,
			name: siteMetadata.defaultTitle,
			headline: siteMetadata.defaultTitle,
		};
  }

  const productionDomain = 'robertgornicki.com';

  return (
    <Helmet title={seoData.title}>
      <html lang={siteMetadata.siteLanguage} />
      <meta name="description" content={seoData.description} />
      <meta name="image" content={seoData.image} />
      <meta
        name="apple-mobile-web-app-title"
        content={siteMetadata.shortName}
      />
      <meta name="application-name" content={siteMetadata.shortName} />
      {/* <meta name="p:domain_verify" content=""/> */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
      <link rel="canonical" href={seoData.url} />
      {article && (
        <meta
          property="article:published_time"
          content={article.datePublishedYear}
        />
      )}
      {article && (
        <meta
          property="article:modified_time"
          content={article.dateModifiedYear}
        />
      )}

      <meta property="og:title" content={seoData.title} key="ogtitle" />
      <meta
        property="og:description"
        content={seoData.description}
        key="ogdesc"
      />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:locale" content={siteMetadata.siteLocale} />
      <meta property="og:see_also" content={siteMetadata.linkedInUrl} />
      <meta property="og:see_also" content={siteMetadata.twitterUrl} />
      <meta property="og:site_name" content={seoData.title} key="ogsitename" />
      <meta property="og:url" content={seoData.url} />
      <meta property="og:image" content={seoData.image} />

      <meta property="twitter:title" content={seoData.title} />
      <meta property="twitter:description" content={seoData.description} />
      <meta property="twitter:image" content={seoData.image} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:creator" content={siteMetadata.twitter} />
      <meta property="twitter:site" content={siteMetadata.twitter} />
      {productionDomain === siteMetadata.baseUrl && (
        <script
          async
          defer
          data-domain={productionDomain}
          src="https://stats.skyisthelimit.waw.pl/js/plausible.outbound-links.js"
        />
      )}
      {children}
    </Helmet>
  );
};

export default SEO;
