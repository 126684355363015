import { createGlobalStyle } from 'styled-components';
import * as v from './variables';

const badgeBackgroundColor = v.colors.brown35;
const badgeColor = v.colors.blue;

const CodeStyles = createGlobalStyle`
  code[data-language] {
    padding: 0.1em 0.2em;
    border-radius: 8px;
  }

  .gatsby-code-title {
    display: inline-block;
    margin: 0 auto;
    font-size: ${v.CODE_BLOCK_FONT_SIZE_EM}em;
    padding: 0.2em 1em;
    font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console',
      'Lucida Sans Typewriter', 'DejaVu Sans Mono', 'Bitstream Vera Sans Mono',
      'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New', Courier,
      monospace;

    background-color: ${v.colors.dark};
    color: ${v.colors.blue55};
    z-index: 1;
    text-align: center;

    border-top-left-radius: 0.3em;
    border-top-right-radius: 0.3em;
    left: 50%;
    position: relative;
    transform: translateX(-50%);
  }

  .grvsc-code {
    position: relative;
    width: 100%;
  }
  .grvsc-line {
    position: static;
  }
  .grvsc-container.default-dark {
    background-color: ${v.colors.dark};
  }
  .grvsc-container.default-dark .grvsc-line-highlighted {
    background-color: ${v.colors.darkBlue};
    box-shadow: inset 0.3em 0 0 0 ${v.colors.brown35};
  }
  .grvsc-container.default-dark .grvsc-line-highlighted::before {
    background-color: transparent;
    box-shadow: none;
  }

  /* scrollbars */
  .grvsc-container {
    scrollbar-color: ${v.scrollbar.thumbColor} ${v.scrollbar.backgroundColor};

    &::-webkit-scrollbar {
      width: ${v.scrollbar.webkit.size};
      height: ${v.scrollbar.webkit.size};
    }
    &::-webkit-scrollbar-thumb {
      background-color: ${v.scrollbar.webkit.thumbColor};
      &:hover {
        background-color: ${v.scrollbar.webkit.thumbHoverColor};
      }
      &:active {
        background-color: ${v.scrollbar.webkit.thumbActiveColor};
      }
    }
    &::-webkit-scrollbar-track {
      background-color: ${v.scrollbar.webkit.backgroundColor};
    }
  }

  /* language badge */
  .grvsc-container[data-language]:not([data-language=""]) {
    padding-top: 1.5em;
    :before {
      content: attr(data-language);
      background: ${badgeBackgroundColor};
      color: ${badgeColor};
      border-radius: 0 0 4px 4px;
      font-size: 0.8em;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
      letter-spacing: 0.075em;
      line-height: 1;
      padding: 0.2em 0.4em;
      position: absolute;
      left: ${1 / 0.75}em;
      top: 0;
      text-align: right;
      text-transform: uppercase;
      font-weight: bold;
    }
  }
`;

export default CodeStyles;
