import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRssSquare } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import styled from 'styled-components';
import MailLink from './MailLink';
import useSiteMetadata from '../hooks/useSiteMetadata';

const Wrapper = styled.nav`
  margin: 0.3em 0;
  & > ul {
    display: inline-flex;
    align-items: center;
    padding: 0;
    margin: 0;

    flex-wrap: wrap;
    justify-content: center;
  }

  & > ul > li {
    list-style: none;
  }

  a {
    transition: color 0.3s;
    font-size: 1.2em;
    padding: 0.5em;
    margin: 0 0.5em;
  }
  a:hover {
    color: ${({ theme }) => {
      return theme.colors.brown35;
    }};
  }
`;

const SocialNav = React.forwardRef(({ header, withRss, ...props }, ref) => {
  const siteMetadata = useSiteMetadata();
  return (
    <Wrapper ref={ref} {...props}>
      {header}
      <ul>
        <li>
          <a
            href={siteMetadata.githubUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View my GitHub profile"
            title="GitHub profile"
          >
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </li>
        <li>
          <a
            href={siteMetadata.linkedInUrl}
            target="_blank"
            rel="noopener noreferrer"
            aria-label="View my LinkedIn profile"
            title="LinkedIn profile"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </li>
        <li>
          <MailLink target="pbagnpg@eboregtbeavpxv.pbz" />
        </li>
        {withRss && (
          <li>
            <a
              href={`${siteMetadata.siteUrl}/rss.xml`}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Subscribe to blog's RSS feed"
              title="RSS feed"
            >
              <FontAwesomeIcon icon={faRssSquare} />
            </a>
          </li>
        )}
      </ul>
    </Wrapper>
  );
});

export default SocialNav;
