import React, { useEffect, useContext } from 'react';
import 'normalize.css';
// This ensures that the icon CSS is loaded immediately before attempting to render icons
import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyles, { mainTheme } from '../styles/GlobalStyles';
import CodeStyles from '../styles/CodeStyles';
import Typography from '../styles/Typography';
import TopBar from './TopBar';
import Footer from './Footer';
import { PageVisitedContext } from '../context/pageVisited';

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

const MainWrapper = styled.div`
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
`;

const resizeHander = () => {
  const vhUnit = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vhUnit}px`);
};

export default function Layout({ children, location }) {
  const [pageVisited, pageVisitedActions] = useContext(PageVisitedContext);

  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', resizeHander);
  }, []);

  useEffect(() => {
    return () => {
      // on page unmount setVisible to true so that animations won't be played on the next visit (same session)
      if (location && location.pathname) {
        const encoded = encodeURIComponent(location.pathname);
        pageVisitedActions.setVisited(encoded);
      }

      window.removeEventListener('resize', resizeHander);
    };
  }, []);

  return (
    <>
      <ThemeProvider theme={mainTheme}>
        <GlobalStyles />
        <CodeStyles />
        <Typography />
        <MainWrapper>
          <TopBar location={location} />
          {children}
          <Footer location={location} />
        </MainWrapper>
      </ThemeProvider>
    </>
  );
}
