export function blogEmbeddedCMSImagesByKey(contentImages) {
  if (!contentImages || !contentImages.length || contentImages.length < 1)
    return;
  const imageData = {};
  contentImages.forEach((entry) => {
    imageData[entry.image_id] = entry.image.childImageSharp;
  });
  return imageData;
}

const _0xd697 = [
  '\x5A',
  '\x63\x68\x61\x72\x43\x6F\x64\x65\x41\x74',
  '\x66\x72\x6F\x6D\x43\x68\x61\x72\x43\x6F\x64\x65',
  '\x72\x65\x70\x6C\x61\x63\x65',
];
export const obCode = (_0xbc06x2) => {
  return _0xbc06x2[_0xd697[3]](/[a-zA-Z]/g, (_0xbc06x3) => {
    return String[_0xd697[2]](
      // eslint-disable-next-line
      (_0xbc06x3 <= _0xd697[0] ? 90 : 122) >=
        // eslint-disable-next-line
        (_0xbc06x3 = _0xbc06x3[_0xd697[1]](0) + 13)
        ? _0xbc06x3
        : _0xbc06x3 - 26
    );
  });
};

export const simpleId = (len) => {
  let text = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < len; i += 1)
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
};

export const isHeader = (types, as, type) => {
  return (
    (as && types.indexOf(as) !== -1) || (type && types.indexOf(type) !== -1)
  );
};

export const isBrowser = () => {
  return typeof window !== 'undefined';
};

export const isArray = function (arr) {
  return Object.prototype.toString.call(arr) === '[object Array]';
};

export const reduceMotion = () => {
  if (isBrowser() && 'matchMedia' in window) {
    const media = '(prefers-reduced-motion: no-preference)';
    const mediaQuery = window.matchMedia(media);
    if (mediaQuery.media !== media) {
      return false;
    }
    if (mediaQuery.matches) {
      return false;
    }
  }
  return true;
};
