import React, { useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { useMobileMenu } from '../context/mobileMenu';
import { bp } from '../styles/mediaBreakpoints';
import { TOP_BAR_BOTTOM_BORDER } from '../styles/variables';
import MailLink from './MailLink';

const menuItemCount = 4;
const menuItemMobileWidth = 100 / menuItemCount;
export const Wrapper = styled.nav`
  position: absolute;
  right: 0;
  top: auto;
  bottom: 0;
  transition: transform 0.5s;
  background-color: ${({ theme }) => {
    return theme.colors.brown35;
  }};
  width: 100%;
  box-sizing: border-box;

  @media (min-width: ${bp.xs}px) {
    height: calc(100% + ${rem(TOP_BAR_BOTTOM_BORDER)});
    max-height: 3em;
    border-bottom: ${rem(TOP_BAR_BOTTOM_BORDER)} solid
      ${({ theme }) => {
        return theme.colors.brown35;
      }};
  }
  @media (min-width: ${bp.md}px) {
    top: 0;
    bottom: auto;
  }

  ul {
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;

    @media (min-width: ${bp.xs}px) {
      height: 100%;
      display: flex;
    }
  }

  li {
    position: relative;
    width: ${menuItemMobileWidth}%;
    height: 100%;
    list-style: none;
    width: 100%;
    padding: 0.7em 0;
    border-bottom: 2px solid
      ${({ theme }) => {
        return theme.colors.blue;
      }};
    &:not(:last-child) {
    }

    @media (min-width: ${bp.xs}px) {
      padding: 0;
      border-bottom: none;
      &:not(:last-child) {
        border-right: 2px solid
          ${({ theme }) => {
            return theme.colors.blue;
          }};
      }
    }
  }

  a {
    transition: color 0.2s;
    position: relative;
    text-decoration: none;
    color: ${({ theme }) => {
      return theme.colors.white;
    }};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${rem(25)};
    line-height: 1;

    &:hover {
      color: ${({ theme }) => {
        return theme.colors.blue;
      }};
    }

    &.active {
      color: ${({ theme }) => {
        return theme.colors.blue;
      }};
    }

    svg {
      margin-top: 0em;
    }
  }
  &.mobile-active {
    transform: translateY(100%);
  }

  @media (min-width: ${bp.md}px) {
    background-color: transparent;
    width: auto;
    height: auto;
    border-bottom: none;
    transition: none;
    top: 50%;
    transform: translateY(-50%);
    a {
      padding: 0.75em;
      margin: 0 0.5em;
      display: flex;
      font-size: unset;
      width: auto;
      height: auto;
      &:not(:last-child) {
        border-right: none;
      }
      &:hover {
        color: ${({ theme }) => {
          return theme.colors.brown35;
        }};
      }

      &.active {
        color: ${({ theme }) => {
          return theme.colors.brown35;
        }};
        &::before {
          opacity: 1;
        }
      }
    }
    &.mobile-active {
      transform: translateY(-50%);
    }
  }
`;
const dynamicLinkData = {
  href: '/blog/',
  title: "View all blog's content",
  text: 'blog',
};
const TopNav = ({ page }) => {
  if (page === 'portfolio') {
    dynamicLinkData.href = '/blog/';
    dynamicLinkData.title = "View all blog's content";
    dynamicLinkData.text = 'blog';
  } else if (page === 'blog') {
    dynamicLinkData.href = '/';
    dynamicLinkData.title = 'Home page';
    dynamicLinkData.text = 'home';
  }
  const [menuState, menuDispatch] = useMobileMenu();
  const wrapperEl = useRef();
  useEffect(() => {
    if (menuState.isActive) {
      wrapperEl.current.classList.add('mobile-active');
    } else {
      wrapperEl.current.classList.remove('mobile-active');
    }
  }, [menuState.isActive]);

  return (
		<Wrapper ref={wrapperEl}>
			<h2 className="sr-only">Main navigation</h2>
			<ul>
				{/* <li>
          <Link
            title={dynamicLinkData.title}
            to={dynamicLinkData.href}
            activeClassName="active"
          >
            {dynamicLinkData.text}
          </Link>
        </li> */}
				<li>
					<a href="https://github.com/robertg042" target="_blank" rel="noopener noreferrer" title="GitHub profile">
						<FontAwesomeIcon icon={faGithub} />
					</a>
				</li>
				<li>
					<a
						href="https://www.linkedin.com/in/robert-gornicki/"
						target="_blank"
						rel="noopener noreferrer"
						title="LinkedIn profile"
					>
						<FontAwesomeIcon icon={faLinkedin} />
					</a>
				</li>
				<li>
					<MailLink target="pbagnpg@eboregtbeavpxv.pbz" />
				</li>
			</ul>
		</Wrapper>
	);
};

export default TopNav;
