// https://kentcdodds.com/blog/how-to-use-react-context-effectively
import * as React from 'react';

const StateContext = React.createContext({
  active: false,
});
const DispatchContext = React.createContext();

function reducer(state, action) {
  switch (action.type) {
    case 'disable': {
      return { isActive: false };
    }
    case 'enable': {
      return { isActive: true };
    }
    case 'toggle': {
      return { isActive: !state.isActive };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function MobileMenuProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, { isActive: false });

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

function useMobileMenuState() {
  const context = React.useContext(StateContext);

  if (context === undefined) {
    throw new Error(
      'useMobileMenuState must be used within a MobileMenuProvider'
    );
  }

  return context;
}

function useMobileMenuDispatch() {
  const context = React.useContext(DispatchContext);

  if (context === undefined) {
    throw new Error(
      'useMobileMenuDispatch must be used within a MobileMenuProvider'
    );
  }

  return context;
}

function useMobileMenu() {
  return [useMobileMenuState(), useMobileMenuDispatch()];
}

export { MobileMenuProvider, useMobileMenu };
