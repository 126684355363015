import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import HomeLink from './HomeLink';
import TopNav, { Wrapper as TopNavWrapper } from './TopNav';
import TopMenuToggle from './TopMenuToggle';
import { MobileMenuProvider } from '../context/mobileMenu';
import { bp } from '../styles/mediaBreakpoints';
import { TOP_BAR_BOTTOM_BORDER } from '../styles/variables';

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => {
    return theme.colors.blue;
  }};
  position: relative;
  color: ${({ theme }) => {
    return theme.colors.white;
  }};
  border-bottom: ${rem(TOP_BAR_BOTTOM_BORDER)} solid transparent;
  text-align: left;
  font-size: ${rem(20)};
  z-index: 2;

  & > * {
    z-index: 2;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  & > h1 {
    position: relative;
    font-size: 1em;
    overflow: hidden;
  }
  ${TopNavWrapper} {
    z-index: 1;
    @media (min-width: ${bp.md}px) {
      z-index: 2;
    }
  }
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => {
    return theme.colors.blue;
  }};
  border-bottom: ${rem(TOP_BAR_BOTTOM_BORDER)} solid
    ${({ theme }) => {
      return theme.colors.brown35;
    }};
`;

const TopBar = ({ location }) => {
  let page = '';
  if (location.pathname === '/') {
    page = 'portfolio';
  }
  return (
    <Wrapper>
      <Background />
      <h1>
        <HomeLink page={page} />
      </h1>
      <MobileMenuProvider>
        <TopMenuToggle />
        <TopNav page={page} />
      </MobileMenuProvider>
    </Wrapper>
  );
};

export default TopBar;
