export const bp = {
  xxs: 320,
  xs: 400,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
};

export const mediaQueries = (key) => {
  return (style) => {
    return `@media (min-width: ${bp[key]}px) { ${style} }`;
  };
};
