import React from 'react';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { obCode } from '../utils';

const MailLink = ({ target }) => {
  const handleClick = (e) => {
    e.preventDefault();
    if (e.isTrusted) {
      setTimeout(() => {
        window.location.href = `${obCode('znvygb')}:${obCode(target)}`;
      }, 500);
    }
  };
  return (
    <a
      href="mailto:me"
      rel="noopener noreferrer"
      aria-label="Send me an e-mail"
      title="E-mail me"
      onClick={handleClick}
    >
      <FA icon={faEnvelope} />
    </a>
  );
};

export default MailLink;
