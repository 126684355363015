import React from 'react';
import styled, { css } from 'styled-components';
import SocialNav from './SocialNav';
import { bp } from '../styles/mediaBreakpoints';
import { SKEW_CLIP_PATH_SIZE_PX } from '../styles/variables';

const Wrapper = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  line-height: 1.4;
  padding-top: 1.5em;

  ${({ theme }) => {
    return css`
      background-color: ${theme.colors.blue10};
      color: ${theme.colors.white};
    `;
  }}

  .footer__invitation {
    margin-bottom: 1em;
    padding: 0 2em;

    @media (min-width: ${bp.lg}px) {
      padding: 0 4em;
    }

    @media (min-width: ${bp.xl}px) {
      padding: 0 8em;
    }
  }

  .footer__info {
    padding: 1em 2em 1em;
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 0;

    ${({ theme }) => {
      return css`
        background-color: ${theme.colors.blue};
        color: ${theme.colors.white};
      `;
    }}

    ${({ theme }) => {
      return theme.mixins.useSkewed(
        css`
          clip-path: polygon(
            0 ${SKEW_CLIP_PATH_SIZE_PX}px,
            100% 0,
            100% 100%,
            0 100%
          );
          padding: 1.5em 2em 1em;
        `
      );
    }}

    @media (min-width: ${bp.md}px) {
      font-size: 0.8em;
    }
  }

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 0.5em;
    ${({ theme }) => {
      return css`
        background: linear-gradient(
          to top,
          ${theme.colors.blue} 0%,
          ${theme.colors.blue} 50%,
          ${theme.colors.brown35} 50%,
          ${theme.colors.brown35} 100%
        );
      `;
    }}
  }
`;

const SocialNavStyled = styled(SocialNav)`
  font-size: 1.6em;
  margin-bottom: 1em;

  ${({ theme }) => {
    return css`
      a {
        color: ${theme.colors.white};
      }
    `;
  }};

  ${({ theme }) => {
    return theme.mixins.useSkewed(
      css`
        margin-bottom: 0.8em;
      `
    );
  }}

  @media (min-width: ${bp.sm}px) {
    font-size: 1em;
  }
`;

const SocialNavHeader = React.createElement(
  'h2',
  { className: 'sr-only' },
  'Mail and social links'
);

const Footer = ({ location }) => {
  let withRss;
  if (location.pathname.indexOf('/blog/') !== -1) {
    withRss = true;
  }
  return (
    <Wrapper>
      <p className="footer__invitation font-cursive font-center font-spacing-3">
        Drop me a line if you have an interesting project to work on or just
        want to chat.
      </p>
      <SocialNavStyled header={SocialNavHeader} withRss={withRss} />
      <p className="footer__info font-serif font-center">
        designed &amp; coded by{' '}
        <span lang="pl-PL" className="font-cursive font-spacing-1">
          Robert&nbsp;Górnicki
        </span>
      </p>
    </Wrapper>
  );
};

export default Footer;
