import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { rem } from 'polished';
import { toggleWidth } from './TopMenuToggle';

const Wrapper = styled(Link)`
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 1em;
  margin-right: ${toggleWidth - 1}em;
  letter-spacing: 0.04em;
  transition: color 0.2s;
  color: ${({ theme }) => {
    return theme.colors.white;
  }};
  text-decoration: none;

  .home-link__under {
    text-transform: uppercase;
    font-size: 0.5em;
    margin-left: 0.8em;
  }

  &:hover {
    color: ${({ theme }) => {
      return theme.colors.brown35;
    }};
  }
`;

const HomeLink = ({ page }) => {
  let title = '';
  let href = '/';
  if (page === 'portfolio') {
    title = 'Home page';
    href = '/';
  }
  return (
    <Wrapper title={title} to={href}>
      <span className="font-cursive font-spacing-1" lang="pl-PL">
        Robert Górnicki
      </span>{' '}
      <span className="home-link__under">{page}</span>
    </Wrapper>
  );
};

export default HomeLink;
