// executing child function from parent pattern: https://stackoverflow.com/a/45582558/9724628

import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { simpleId, reduceMotion } from '../utils';

const Wrapper = styled.svg`
  width: 100%;
`;

const SvgMorph = ({
  kutejs,
  pathVariants,
  changeVariant,
  initialVariant,
  className,
  viewBox,
  fill = 'black',
  duration = 300,
  easing = 'easingCubicInOut',
}) => {
  const [state, setState] = useState(initialVariant);
  const idPrefix = 'svg-morph';
  const idRef = useRef(simpleId(6));

  const kutejsRef = useRef();

  let initialPath;
  if (pathVariants[initialVariant]) {
    initialPath = pathVariants[initialVariant];
  } else {
    throw new Error('initialVariant prop not provided');
  }
  const pathRefs = useRef([]);
  pathRefs.current = initialPath.map(() => {
    return React.createRef();
  });

  useEffect(() => {
    if (kutejs) {
      kutejsRef.current = kutejs;
    }
  }, [kutejs]);

  const getPathData = useCallback(
    (pathId) => {
      let paths;
      if (!pathId) {
        paths = initialPath;
      } else {
        for (const key of Object.keys(pathVariants)) {
          if (key === pathId) {
            paths = pathVariants[key];
          }
        }
      }
      return paths;
    },
    [initialPath, pathVariants]
  );

  const morph = useCallback(
    (KUTE, pathId) => {
      const paths = getPathData(pathId);
      pathRefs.current.forEach((pathRef, index) => {
        KUTE.to(
          pathRef.current, // target
          { path: paths[index] },
          {
            easing,
            duration,
          }
        ).start();
      });
    },
    [duration, easing, getPathData]
  );

  const morphFallback = useCallback(
    (pathId) => {
      const paths = getPathData(pathId);
      pathRefs.current.forEach((pathRef, index) => {
        pathRef.current.setAttribute('d', paths[index]);
      });
    },
    [getPathData]
  );

  const handleChangeState = useCallback((newState) => {
    setState(newState);
  }, []);

  useEffect(() => {
    changeVariant(handleChangeState);
  }, [changeVariant, handleChangeState]);

  useEffect(() => {
    if (kutejsRef.current && !reduceMotion()) {
      morph(kutejsRef.current, state);
    } else {
      morphFallback(state);
    }
  }, [state, morph, morphFallback]);

  return (
    <Wrapper
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      className={className}
    >
      {initialPath.map((pathData, index) => {
        const id = `${idPrefix}-${idRef.current}-${index}`;
        return (
          <path
            key={id}
            id={id}
            fill={fill}
            d={pathData}
            ref={pathRefs.current[index]}
          />
        );
      })}
    </Wrapper>
  );
};

export default SvgMorph;
